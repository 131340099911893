

























































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/models/app.model';
import { DISPLAY_INTERVALS_LIGHT, DISPLAY_INTERVALS_SECONDS } from '@/constants/filters.constants';
import { HumanizedEvent } from '@/models/event.model';
import DisplayIntervalSelect from '@/components/shared/DisplayIntervalSelect.component.vue';
import { EventsService } from '@/services/events.service';

@Component({
  name: 'DateTimeSelector',
  components: {
    DisplayIntervalSelect,
  },
})
export default class DateTimeSelector extends Vue {
  @Prop() public date!: string;
  @Prop() public time!: string;
  @Prop() public disabled?: boolean;
  @Prop({ default: true }) public dense?: boolean;
  @Prop({ default: false }) public required?: boolean;
  @Prop() public hideEvents?: boolean;
  @Prop() public hideDisplayInterval?: boolean;
  @Prop() public displayInterval!: number;
  @Prop() public stationIds!: string[];
  @Prop() public useLightIntervals!: boolean;
  @Prop({ default: 'Start Date/Time' }) public label!: string;
  @Prop({ default: 'mdi-timelapse' }) public icon!: string | null;

  public dialogToggle: boolean = false;
  public dialogTab: number = 0;
  public displayIntervals: Options<number>[] = [];
  public knownDisplayIntervals: number[] = [];
  public innerDate: string = '';
  public innerTime: string = '';
  public innerDisplayInterval: number = 0;
  public events: HumanizedEvent[] = [];

  public mounted() {
    this.displayIntervals = this.useLightIntervals ? DISPLAY_INTERVALS_LIGHT : DISPLAY_INTERVALS_SECONDS;

    this.knownDisplayIntervals = this.displayIntervals.map((displayInterval) => displayInterval.value);

    if (!this.hideEvents) {
      this.loadEvents();
    }

    this.onDateChange();
    this.onTimeChange();
    this.onDisplayIntervalChange();
  }

  public isKnownDisplayInterval(displayInterval: number) {
    return _.includes(this.knownDisplayIntervals, displayInterval);
  }

  public onEventSelect(event: HumanizedEvent) {
    this.innerDisplayInterval = event.paddedDuration;
    this.innerDate = event.startDate;
    this.innerTime = event.paddedStartTimestamp;
    this.$emit('update:date', this.innerDate);
    this.$emit('update:time', this.innerTime);
    this.$emit('update:displayInterval', this.innerDisplayInterval);

    this.dialogToggle = false;
  }

  @Watch('date')
  public onDateChange() {
    this.innerDate = _.clone(this.date);
  }

  @Watch('time')
  public onTimeChange() {
    this.innerTime = _.clone(this.time);
  }

  @Watch('displayInterval')
  public onDisplayIntervalChange() {
    this.innerDisplayInterval = _.clone(this.displayInterval);
  }

  @Watch('stationIds')
  public onStationIdsChange() {
    this.loadEvents();
  }

  @Watch('innerDisplayInterval')
  public onInnerDisplayIntervalChange() {
    this.$emit('update:displayInterval', this.innerDisplayInterval);
  }

  private loadEvents() {
    let stationIds: string[] = [];
    if (_.isArray(this.stationIds)) {
      stationIds = this.stationIds;
    } else if (this.stationIds) {
      stationIds = [this.stationIds];
    }

    EventsService.query({
      count: 1000,
      start: 0,
      stationIds,
    }).then((response) => {
      this.events = response.elements;
    });
  }
}

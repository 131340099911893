import { Options, TreeOptions } from '@/models/app.model';

export const DISPLAY_INTERVALS: Options<number>[] = [
  { name: '1 min', value: 1 },
  { name: '5 min', value: 5 },
  { name: '10 min', value: 10 },
  { name: '15 min', value: 15 },
  { name: '30 min', value: 30 },
];

export const DISPLAY_INTERVALS_SECONDS: Options<number>[] = [
  { name: '1 min', value: 60 },
  { name: '5 min', value: 300 },
  { name: '1 hour', value: 3600 },
  { name: '1 day', value: 86400 },
];

export const DISPLAY_INTERVALS_LIGHT: Options<number>[] = [
  { name: '5 s', value: 5 },
  { name: '30 s', value: 30 },
  { name: '1 min', value: 60 },
  { name: '5 min', value: 300 },
];

export const SENSOR_PROVISIONING_OPTIONS: Options<string>[] = [
  { name: 'Acceleration', value: 'a' },
  { name: 'Velocity', value: 'v' },
  { name: 'Both', value: 'av' },
];

export const SENSOR_PROVISIONING_CHANNELS_OPTIONS: Options<number[]>[] = [
  { name: 'a', value: [0, 1, 2] },
  { name: 'v', value: [3, 4, 5] },
  { name: 'av', value: [0, 1, 2, 3, 4, 5] },
];

export const CHANNEL_OPTIONS: Options<number[]>[] = [
  { name: 'Acceleration', value: [0, 1, 2] },
  { name: 'Velocity', value: [3, 4, 5] },
];

export const CHANNEL_TREE: TreeOptions[] = [
  {
    id: 6,
    name: 'Acceleration',
    children: [
      { id: 0, name: 'Acceleration Z' },
      { id: 1, name: 'Acceleration E' },
      { id: 2, name: 'Acceleration N' },
    ],
  },
  {
    id: 7,
    name: 'Velocity',
    children: [
      { id: 3, name: 'Velocity Z' },
      { id: 4, name: 'Velocity E' },
      { id: 5, name: 'Velocity N' },
    ],
  },
];

export const REGIONAL_THRESHOLD_LEVELS: Options<string>[] = [
  { name: 'Weak', value: 'WEAK' },
  { name: 'Medium', value: 'MEDIUM' },
  { name: 'Strong', value: 'STRONG' },
];

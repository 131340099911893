




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'LoadingBar',
})
export default class LoadingBar extends Vue {
  @Prop() public isLoading!: boolean;
}

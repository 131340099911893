














import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/models/app.model';
import { DISPLAY_INTERVALS_LIGHT, DISPLAY_INTERVALS_SECONDS } from '@/constants/filters.constants';

@Component({
  name: 'DisplayIntervalSelect',
})
export default class DisplayIntervalSelect extends Vue {
  @Prop() public model!: string[];
  @Prop() public disabled?: boolean;
  @Prop() public useLightIntervals!: boolean;

  public innerModel: string[] = [];
  public displayIntervals: Options<number>[] = [];

  public mounted() {
    this.displayIntervals = this.useLightIntervals ? DISPLAY_INTERVALS_LIGHT : DISPLAY_INTERVALS_SECONDS;
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}

import axios from 'axios';
import moment from 'moment-timezone';
import numeral from 'numeral';
import _, { sortBy } from 'lodash';
import {
  CatalogLocation,
  CompleteEvent,
  CompleteHumanizedEvent,
  Event,
  EventDataQueryParams,
  EventRegional,
  EventRegionalQueryParams,
  EventsQueryParams,
  EventsQueryResponse,
  HumanizedEvent,
  HumanizedRegionalEvent,
} from '@/models/event.model';
import { MessageSource } from '@/models/message.model';
import { roundToDecimal, scientificNotation } from '@/filters/number.filter';
import { DATE_DEFAULT_FORMAT, DATE_HOUR_FORMAT } from '@/constants/app.constants';
import { ChartConfiguration, YAxisPlotLine } from '@/models/chart.model';
import { getNameByChannelId } from '@/filters/channel.filter';
import { FiltersEvents, FiltersEventsRegional } from '@/models/states/filters-state.model';
import { FILTER_TYPE } from '@/constants/readings.constants';
import { GeometryUtils } from '@/utils/geometry.utils';

const intensityPolygons = [
  {
    intensity: 7,
    polygon: [
      [35.497, 23.3059],
      [35.4221, 23.3948],
      [35.3787, 23.4488],
      [35.2792, 23.5799],
    ],
  },
  {
    intensity: 7,
    polygon: [
      [40.4886, 29.163],
      [40.5649, 29.0327],
      [40.5721, 29.0202],
      [40.649, 28.8773],
      [40.7078, 28.7576],
      [40.719, 28.7345],
      [40.7836, 28.5916],
      [40.8421, 28.4488],
      [40.8506, 28.4262],
      [40.8961, 28.3059],
      [40.9449, 28.163],
      [40.9886, 28.0202],
      [40.9935, 28.0025],
      [41.0286, 27.8773],
      [41.0643, 27.7345],
      [41.0955, 27.5916],
      [41.1226, 27.4488],
      [41.1363, 27.3638],
    ],
  },
  {
    intensity: 7,
    polygon: [
      [41.1363, 25.5487],
      [41.1199, 25.4488],
      [41.0924, 25.3059],
      [41.0607, 25.163],
      [41.0246, 25.0202],
      [40.9935, 24.9107],
      [40.9841, 24.8773],
      [40.9399, 24.7345],
      [40.8905, 24.5916],
      [40.8506, 24.4868],
      [40.8361, 24.4488],
      [40.777, 24.3059],
      [40.7117, 24.163],
      [40.7078, 24.155],
      [40.6411, 24.0202],
      [40.5649, 23.88],
      [40.5634, 23.8773],
      [40.4792, 23.7345],
      [40.4221, 23.6448],
      [40.3869, 23.5916],
      [40.286, 23.4488],
      [40.2792, 23.4396],
      [40.1751, 23.3059],
    ],
  },
  {
    intensity: 8,
    polygon: [
      [38.47, 29.163],
      [38.5649, 29.1251],
      [38.7078, 29.0536],
      [38.7635, 29.0202],
      [38.8506, 28.9667],
      [38.9734, 28.8773],
      [38.9935, 28.8622],
      [39.1363, 28.7381],
      [39.1401, 28.7345],
      [39.2786, 28.5916],
      [39.2792, 28.5909],
      [39.3967, 28.4488],
      [39.4221, 28.4151],
      [39.4992, 28.3059],
      [39.5649, 28.2019],
      [39.5884, 28.163],
      [39.6666, 28.0202],
      [39.7078, 27.9349],
      [39.735, 27.8773],
      [39.7949, 27.7345],
      [39.8462, 27.5916],
      [39.8506, 27.5774],
      [39.8913, 27.4488],
      [39.9293, 27.3059],
      [39.9605, 27.163],
      [39.9852, 27.0202],
      [39.9935, 26.9582],
      [40.0047, 26.8773],
      [40.0186, 26.7345],
      [40.0268, 26.5916],
      [40.0293, 26.4488],
      [40.0262, 26.3059],
      [40.0174, 26.163],
      [40.0028, 26.0202],
      [39.9935, 25.9547],
      [39.9829, 25.8773],
      [39.9574, 25.7345],
      [39.9256, 25.5916],
      [39.8868, 25.4488],
      [39.8506, 25.3359],
      [39.8411, 25.3059],
      [39.7889, 25.163],
      [39.728, 25.0202],
      [39.7078, 24.9778],
      [39.6588, 24.8773],
      [39.5793, 24.7345],
      [39.5649, 24.7109],
      [39.4888, 24.5916],
      [39.4221, 24.4977],
      [39.3848, 24.4488],
      [39.2792, 24.3219],
      [39.2647, 24.3059],
      [39.1363, 24.1747],
      [39.1236, 24.163],
      [38.9935, 24.0506],
      [38.953, 24.0202],
      [38.8506, 23.9461],
      [38.7378, 23.8773],
      [38.7078, 23.8595],
      [38.5649, 23.7876],
      [38.4309, 23.7345],
      [38.4221, 23.7309],
      [38.2792, 23.6863],
      [38.1363, 23.6548],
      [37.9935, 23.6354],
      [37.8506, 23.6279],
      [37.7078, 23.6318],
      [37.5649, 23.6472],
      [37.4221, 23.6744],
      [37.2792, 23.7139],
      [37.2225, 23.7345],
      [37.1363, 23.7654],
      [36.9935, 23.83],
      [36.9072, 23.8773],
      [36.8506, 23.9088],
      [36.7078, 24.003],
      [36.6849, 24.0202],
      [36.5649, 24.1139],
      [36.5094, 24.163],
      [36.4221, 24.2446],
      [36.3627, 24.3059],
      [36.2792, 24.3985],
      [36.2374, 24.4488],
      [36.1363, 24.5811],
      [36.1288, 24.5916],
      [36.0338, 24.7345],
      [35.9935, 24.8023],
      [35.9505, 24.8773],
      [35.8775, 25.0202],
      [35.8506, 25.0797],
      [35.8135, 25.163],
      [35.7577, 25.3059],
      [35.7104, 25.4488],
      [35.7078, 25.4581],
      [35.6691, 25.5916],
      [35.6348, 25.7345],
      [35.6075, 25.8773],
      [35.5866, 26.0202],
      [35.5718, 26.163],
      [35.5649, 26.2733],
      [35.5628, 26.3059],
      [35.5594, 26.4488],
      [35.5621, 26.5916],
      [35.5649, 26.6373],
      [35.5706, 26.7345],
      [35.5847, 26.8773],
      [35.6049, 27.0202],
      [35.6316, 27.163],
      [35.665, 27.3059],
      [35.7057, 27.4488],
      [35.7078, 27.455],
      [35.7522, 27.5916],
      [35.807, 27.7345],
      [35.8506, 27.8333],
      [35.8703, 27.8773],
      [35.9421, 28.0202],
      [35.9935, 28.1107],
      [36.0243, 28.163],
      [36.1179, 28.3059],
      [36.1363, 28.3318],
      [36.2249, 28.4488],
      [36.2792, 28.5145],
      [36.3483, 28.5916],
      [36.4221, 28.6684],
      [36.4924, 28.7345],
      [36.5649, 28.7991],
      [36.6645, 28.8773],
      [36.7078, 28.9101],
      [36.8506, 29.0039],
      [36.8797, 29.0202],
      [36.9935, 29.083],
      [37.1363, 29.1473],
      [37.1799, 29.163],
    ],
  },
  {
    intensity: 9,
    polygon: [
      [36.3897, 26.0202],
      [36.3658, 26.163],
      [36.3516, 26.3059],
      [36.3466, 26.4488],
      [36.3506, 26.5916],
      [36.3638, 26.7345],
      [36.3866, 26.8773],
      [36.4197, 27.0202],
      [36.4221, 27.0281],
      [36.4615, 27.163],
      [36.5156, 27.3059],
      [36.5649, 27.4112],
      [36.5831, 27.4488],
      [36.6651, 27.5916],
      [36.7078, 27.655],
      [36.7662, 27.7345],
      [36.8506, 27.8345],
      [36.8921, 27.8773],
      [36.9935, 27.9725],
      [37.0551, 28.0202],
      [37.1363, 28.0795],
      [37.2792, 28.1605],
      [37.2853, 28.163],
      [37.4221, 28.2212],
      [37.5649, 28.2613],
      [37.7078, 28.2826],
      [37.8506, 28.2861],
      [37.9935, 28.272],
      [38.1363, 28.2394],
      [38.2792, 28.1864],
      [38.3249, 28.163],
      [38.4221, 28.1124],
      [38.5548, 28.0202],
      [38.5649, 28.0127],
      [38.7078, 27.8835],
      [38.7136, 27.8773],
      [38.8357, 27.7345],
      [38.8506, 27.7145],
      [38.9339, 27.5916],
      [38.9935, 27.486],
      [39.0134, 27.4488],
      [39.0787, 27.3059],
      [39.1301, 27.163],
      [39.1363, 27.1414],
      [39.1719, 27.0202],
      [39.2033, 26.8773],
      [39.2249, 26.7345],
      [39.2374, 26.5916],
      [39.2412, 26.4488],
      [39.2365, 26.3059],
      [39.223, 26.163],
      [39.2004, 26.0202],
      [39.1679, 25.8773],
      [39.1363, 25.7722],
      [39.1252, 25.7345],
      [39.0724, 25.5916],
      [39.0055, 25.4488],
      [38.9935, 25.4266],
      [38.9243, 25.3059],
      [38.8506, 25.1985],
      [38.8238, 25.163],
      [38.7078, 25.0294],
      [38.6985, 25.0202],
      [38.5649, 24.8999],
      [38.5338, 24.8773],
      [38.4221, 24.8004],
      [38.2943, 24.7345],
      [38.2792, 24.7269],
      [38.1363, 24.6735],
      [37.9935, 24.6406],
      [37.8506, 24.6265],
      [37.7078, 24.63],
      [37.5649, 24.6514],
      [37.4221, 24.6918],
      [37.321, 24.7345],
      [37.2792, 24.7521],
      [37.1363, 24.8335],
      [37.0758, 24.8773],
      [36.9935, 24.9404],
      [36.9077, 25.0202],
      [36.8506, 25.0784],
      [36.7785, 25.163],
      [36.7078, 25.2579],
      [36.6751, 25.3059],
      [36.5911, 25.4488],
      [36.5649, 25.5019],
      [36.5222, 25.5916],
      [36.4667, 25.7345],
      [36.4238, 25.8773],
      [36.4221, 25.8849],
      [36.3897, 26.0202],
    ],
  },
  {
    intensity: 10,
    polygon: [
      [36.9505, 26.0202],
      [36.9118, 26.163],
      [36.8895, 26.3059],
      [36.8818, 26.4488],
      [36.888, 26.5916],
      [36.9087, 26.7345],
      [36.9455, 26.8773],
      [36.9935, 27.002],
      [37.0007, 27.0202],
      [37.0762, 27.163],
      [37.1363, 27.2498],
      [37.1822, 27.3059],
      [37.2792, 27.4044],
      [37.3378, 27.4488],
      [37.4221, 27.507],
      [37.5649, 27.5711],
      [37.6559, 27.5916],
      [37.7078, 27.6038],
      [37.8506, 27.608],
      [37.9428, 27.5916],
      [37.9935, 27.583],
      [38.1363, 27.5283],
      [38.2601, 27.4488],
      [38.2792, 27.4354],
      [38.4125, 27.3059],
      [38.4221, 27.2948],
      [38.516, 27.163],
      [38.5649, 27.0719],
      [38.5905, 27.0202],
      [38.6442, 26.8773],
      [38.6795, 26.7345],
      [38.6992, 26.5916],
      [38.7051, 26.4488],
      [38.6978, 26.3059],
      [38.6765, 26.163],
      [38.6394, 26.0202],
      [38.5835, 25.8773],
      [38.5649, 25.8406],
      [38.5065, 25.7345],
      [38.4221, 25.6182],
      [38.3987, 25.5916],
      [38.2792, 25.4773],
      [38.2376, 25.4488],
      [38.1363, 25.3846],
      [37.9935, 25.3294],
      [37.8528, 25.3059],
      [37.8506, 25.3055],
      [37.8379, 25.3059],
      [37.7078, 25.3094],
      [37.5649, 25.3415],
      [37.4221, 25.406],
      [37.3594, 25.4488],
      [37.2792, 25.5084],
      [37.1961, 25.5916],
      [37.1363, 25.6631],
      [37.086, 25.7345],
      [37.0076, 25.8773],
      [36.9935, 25.9117],
      [36.9505, 26.0202],
    ],
  },
  {
    intensity: 11,
    polygon: [
      [37.2755, 26.3059],
      [37.2614, 26.4488],
      [37.2727, 26.5916],
      [37.2792, 26.6172],
      [37.3097, 26.7345],
      [37.3803, 26.8773],
      [37.4221, 26.935],
      [37.5095, 27.0202],
      [37.5649, 27.0635],
      [37.7078, 27.1213],
      [37.8506, 27.127],
      [37.9935, 27.082],
      [38.0807, 27.0202],
      [38.1363, 26.9718],
      [38.2088, 26.8773],
      [38.2788, 26.7345],
      [38.2792, 26.7331],
      [38.3164, 26.5916],
      [38.3275, 26.4488],
      [38.3137, 26.3059],
      [38.2792, 26.1833],
      [38.2713, 26.163],
      [38.1749, 26.0202],
      [38.1363, 25.9779],
      [38.0059, 25.8773],
      [37.9935, 25.8689],
      [37.8506, 25.8096],
      [37.7078, 25.7953],
      [37.5649, 25.8495],
      [37.5285, 25.8773],
      [37.4221, 25.9776],
      [37.3904, 26.0202],
      [37.3155, 26.163],
      [37.2792, 26.2923],
      [37.2755, 26.3059],
    ],
  },
  {
    intensity: 12,
    polygon: [
      [37.624, 26.3059],
      [37.5815, 26.4488],
      [37.6155, 26.5916],
      [37.7078, 26.7019],
      [37.8506, 26.7176],
      [37.9774, 26.5916],
      [37.9935, 26.517],
      [38.0027, 26.4488],
      [37.9935, 26.4305],
      [37.9065, 26.3059],
      [37.8506, 26.2527],
      [37.7078, 26.2211],
      [37.624, 26.3059],
    ],
  },
];

export class EventsService {
  public static query(params: Partial<EventsQueryParams> = {}): Promise<EventsQueryResponse> {
    return axios
      .post<EventsQueryResponse>(`/events/query`, params)
      .then((response) => response.data)
      .then((response) => {
        _.set(
          response,
          'elements',
          response.elements.map((event) => EventsService.parseEvent(event)),
        );
        return response;
      });
  }

  public static getById(eventId: string): Promise<HumanizedEvent> {
    return axios
      .get<CompleteHumanizedEvent>(`/events/${eventId}/`)
      .then((response) => response.data)
      .then((event) => EventsService.parseEvent(event));
  }

  public static get(eventId: string, params: Partial<EventDataQueryParams>): Promise<CompleteHumanizedEvent> {
    return axios
      .post<CompleteHumanizedEvent>(`/events/${eventId}/data/query`, params)
      .then((response) => response.data)
      .then((event: CompleteEvent) => EventsService.parseEvent(event) as CompleteHumanizedEvent)
      .then((event: CompleteHumanizedEvent) => EventsService.parseCompleteEvent(event));
  }

  public static getMeta(eventId: string): Promise<CompleteHumanizedEvent> {
    return EventsService.get(eventId, { includeSeries: false });
  }

  public static getFiltered(eventId: string, filters: FiltersEvents, event: CompleteHumanizedEvent): Promise<CompleteHumanizedEvent> {
    return EventsService.get(eventId, EventsService.getQueryParams(filters, event));
  }

  public static getRegional(eventId: string, params: Partial<EventRegionalQueryParams>): Promise<HumanizedRegionalEvent> {
    return axios
      .post<HumanizedRegionalEvent>(`/events/${eventId}/regional/query`, params)
      .then((response) => response.data)
      .then((event: EventRegional) => EventsService.parseRegionalEvent(event));
  }

  public static getRegionalMeta(eventId: string): Promise<HumanizedRegionalEvent> {
    return EventsService.getRegional(eventId, { includeSeries: false });
  }

  public static getRegionalFiltered(
    eventId: string,
    filters: FiltersEventsRegional,
    eventMeta: HumanizedRegionalEvent,
    includeSeries: boolean = true,
  ): Promise<HumanizedRegionalEvent> {
    return EventsService.getRegional(eventId, EventsService.getRegionalQueryParams(filters, eventMeta, includeSeries));
  }

  public static saveCatalogLocation(eventId: string, catalogLocation: CatalogLocation) {
    return axios.post<HumanizedRegionalEvent>(`/events/${eventId}/location`, catalogLocation).then((response) => response.data);
  }

  public static removeCatalogLocation(eventId: string, catalogLocationId: number) {
    return axios.delete<HumanizedRegionalEvent>(`/events/${eventId}/location/${catalogLocationId}`).then((response) => response.data);
  }

  public static extractReadingsFromRegionalEvent(regionalEvent: HumanizedRegionalEvent): ChartConfiguration[] {
    return _.union(EventsService.getRegionalExtraReadings(regionalEvent), EventsService.getRegionalChannelReadings(regionalEvent));
  }

  public static extractReadingsFromEvent(event: CompleteHumanizedEvent, filters: FiltersEvents): ChartConfiguration[] {
    return _.union(EventsService.getExtraSeries(event, filters), EventsService.getChannelReadings(event, filters));
  }

  public static getQueryParams(filters: FiltersEvents, event: CompleteHumanizedEvent): Partial<EventDataQueryParams> {
    let readingFilterType = null;

    if (event.originalDataFilter === null || event.originalDataFilter?.filterType === FILTER_TYPE.NONE) {
      readingFilterType = filters.filterType ? filters.filterType : null;
    }

    return {
      channels: filters.channels ? filters.channels : null,
      from: filters.startDate !== 0 ? filters.startDate : null,
      includeSeries: true,
      nodes: filters.nodes ? filters.nodes : null,
      readingFilterType,
      preferredUnits: filters.unit ? filters.unit : null,
      to: filters.endDate !== 0 ? filters.endDate : null,
    };
  }

  public static getRegionalQueryParams(
    filters: FiltersEventsRegional,
    event: HumanizedRegionalEvent,
    includeSeries: boolean,
  ): Partial<EventRegionalQueryParams> {
    let readingFilterType = null;

    if (event.originalDataFilter === null || event.originalDataFilter?.filterType === FILTER_TYPE.NONE) {
      readingFilterType = filters.filterType ? filters.filterType : null;
    }
    return {
      from: filters?.startDate !== 0 ? filters?.startDate : null,
      includeSeries,
      preferredUnits: filters.unit ? filters.unit : null,
      readingFilterType,
      observedTime: filters?.observedTime !== 0 ? filters?.observedTime : null,
      to: filters?.endDate !== 0 ? filters?.endDate : null,
    };
  }

  public static updatePublishStatus(eventId: string) {
    return axios.post<HumanizedRegionalEvent>(`/events/${eventId}/published`).then((response) => response.data);
  }

  private static getChannelReadings(event: CompleteHumanizedEvent, filters: FiltersEvents): ChartConfiguration[] {
    return _(event.channelReadings)
      .filter((channelReading) => _.includes(filters?.channels, channelReading.channelId))
      .filter((channelReading) => _.includes(filters?.nodes, channelReading.nodeId))
      .groupBy('channelId')
      .map((channel, channelId: string) => ({
        name: getNameByChannelId(parseInt(channelId, 10)),
        key: channelId,
        series: channel.map((node) => ({
          name: `Node ${node.nodeId}`,
          key: `${node.nodeId}`,
          units: node.units,
          data: node.data,
        })),
      }))
      .value();
  }

  private static getExtraSeries(event: CompleteHumanizedEvent, filters: FiltersEvents): ChartConfiguration[] {
    const startDate = filters?.startDate || 0;
    const endDate = filters?.endDate || 0;
    const LINES_SERIES = [
      { name: 'Quality', key: 'qFactorLine' },
      { name: 'Magnitude', key: 'magLine' },
      { name: 'Slowness', key: 'sloLine' },
      { name: 'BAZ', key: 'bazLine' },
    ];
    const WAVE_BEAM_SERIES = [
      { name: 'P Beam', key: 'pBeamLine' },
      { name: 'S Beam', key: 'sBeamLine' },
    ];
    const yPlotLines: { [key: string]: YAxisPlotLine[] } = {
      's&pBeams': [{ value: 1, color: '#808080', width: 1 }],
      qFactorLine: [{ value: 1, color: '#808080', width: 1 }],
      PsPol: [{ value: 1, color: '#808080', width: 1 }],
      Stalta: [{ value: 1, color: '#808080', width: 1 }],
      HOverV: [{ value: 1.414, color: '#808080', width: 1 }],
    };

    const extraSeries: unknown[] = [];

    extraSeries.push({
      name: 'S&P Beams',
      key: 's&pBeams',
      yAxisPlotLines: yPlotLines['s&pBeams'] ?? [],
      series: WAVE_BEAM_SERIES.map((extra) => ({
        name: extra.name,
        key: extra.key,
        units: '',
        data:
          startDate !== 0 || endDate !== 0
            ? _.get(event, extra.key, []).filter((item: [number, number]) => item[0] >= startDate && item[0] <= endDate)
            : _.get(event, extra.key, []),
      })),
    });

    extraSeries.push(
      ...LINES_SERIES.map((extra) => ({
        name: extra.name,
        key: extra.key,
        yAxisPlotLines: yPlotLines[extra.key] ?? [],
        series: [
          {
            name: extra.name,
            key: extra.key,
            data: _.get(event, extra.key, []),
            units: '',
          },
        ],
      })),
    );

    if (event.metaSeries) {
      event.metaSeries.forEach((series) => {
        extraSeries.push({
          name: series.name,
          key: series.name,
          yAxisPlotLines: yPlotLines[series.name] ?? [],
          series: [
            {
              name: series.name,
              key: series.key,
              data: series.values,
              units: series.units,
            },
          ],
        });
      });
    }
    return extraSeries as ChartConfiguration[];
  }

  private static getRegionalChannelReadings(regionalEvent: HumanizedRegionalEvent): ChartConfiguration[] {
    if (!regionalEvent) {
      return [];
    }

    return _(regionalEvent.readings)
      .groupBy('stationId')
      .map((station, stationId: string) => ({
        name: `Station ${stationId}`,
        key: stationId,
        series: station.map((node) => ({
          name: `Node ${node.nodeId} Channel ${getNameByChannelId(node.channelId)}`,
          key: `${node.nodeId}`,
          data: node.data,
          units: node.units,
        })),
      }))
      .value();
  }

  private static getRegionalExtraReadings(regionalEvent: HumanizedRegionalEvent): ChartConfiguration[] {
    if (!regionalEvent) {
      return [];
    }

    const EXTRA_READINGS = [
      { name: 'Stress Drop (MPa)', key: 'stressDropLine', rescaleFactor: 0.000001 },
      { name: 'Moment (Nm)', key: 'momentLine', rescaleFactor: 1 },
      { name: 'Magnitude (Mw)', key: 'magLine', rescaleFactor: 1 },
    ];

    const extraReadings: any[] = EXTRA_READINGS.map((extra) => {
      const data: number[][] = _.get(regionalEvent, extra.key, []);
      const rescaledData =
        extra.rescaleFactor && extra.rescaleFactor !== 1 ? data.map((datapoint) => [datapoint[0], datapoint[1] * extra.rescaleFactor]) : data;
      return {
        name: extra.name,
        key: extra.key,
        series: [
          {
            name: extra.name,
            key: extra.key,
            data: rescaledData,
            units: '',
          },
        ],
      };
    });

    return extraReadings.reverse();
  }

  private static parseRegionalEvent(event: EventRegional): HumanizedRegionalEvent {
    const sourceMessage: MessageSource = _.get(event, 'lastSourceMessage');
    const duration = event.endTime - event.startTime;
    const endTime = duration > 180000 ? sourceMessage.time : event.endTime;

    if (!sourceMessage) {
      return _.extend({}, event, {
        // going back in time 10s because of https://mdhtec.atlassian.net/browse/SEIS-1129
        startTime: event.startTime - 10000,
        endTime: duration > 180000 ? event.startTime - 10000 + 180000 : event.endTime,
        parsedDate: '',
        parsedDateInverse: '',
        parsedOriginTime: '',
        parsedOriginTimeInverse: '',
        parsedLocation: '',
        parsedMagnitude: '',
        parsedMagnitudeSimple: '',
        parsedMoment: '',
        parsedStressDrop: '',
      });
    }
    return _.extend({}, event, {
      // Enable mock intensityPolygons
      // lastSourceMessage: _.extend({}, event.lastSourceMessage, {
      //   intensityPolygons,
      // }),
      // going back in time 10s because of https://mdhtec.atlassian.net/browse/SEIS-1129
      startTime: event.startTime - 10000,
      endTime,
      pointsOfInterest: sortBy(event.pointsOfInterest, 'distanceToHypocenterKm'),
      parsedDate: moment(sourceMessage.time).format('YYYY-MM-DD HH:mm:ss'),
      parsedDateInverse: moment(sourceMessage.time).format('DD-MM-YYYY, HH:mm:ss'),
      parsedOriginTime: moment(sourceMessage.originTime).format('HH:mm:ss'),
      parsedOriginTimeInverse: moment(sourceMessage.originTime).format('DD-MM-YYYY, HH:mm:ss'),
      parsedLocation: event.location ? `${roundToDecimal(event.location.lat, 3)}, ${roundToDecimal(event.location.long, 3)}` : 'Not Determined',
      parsedMagnitude: `${roundToDecimal(event.magnitude.min, 3)} - ${roundToDecimal(event.magnitude.max, 3)}`,
      parsedMagnitudeSimple: `${roundToDecimal(event.magnitude.min, 1)}`,
      parsedMoment: `${scientificNotation(event.moment.min)} - ${scientificNotation(event.moment.max)}`,
      parsedStressDrop: event.stressDrop
        ? `${event.stressDrop.min && roundToDecimal(event.stressDrop.min / 1000000, 3)} - ${
            event.stressDrop.max && roundToDecimal(event.stressDrop.max / 1000000, 3)
          }`
        : 'NA',
    });
  }

  private static parseEvent(event: Event): HumanizedEvent {
    const startMoment = moment.unix(event.startTime / 1000);
    const endMoment = moment.unix(event.endTime / 1000);
    const secondsStartPadding = 15;
    const secondsEndPadding = 1;
    const startDate = startMoment.format(DATE_DEFAULT_FORMAT);
    const displayStartTimestamp = startMoment.format('HH:mm:ss ZZ');
    const startDateTime = `${startDate} ${displayStartTimestamp}`;

    return _.extend({}, event, {
      displayStartTimestamp,
      duration: endMoment.diff(startMoment, 'seconds'),
      endDate: endMoment.format('YYYY-MM-DD HH:mm:ss ZZ'),
      paddedDuration: endMoment.clone().diff(startMoment, 'seconds') + secondsStartPadding + secondsEndPadding,
      paddedStartTimestamp: startMoment.clone().subtract(secondsStartPadding, 'seconds').format(DATE_HOUR_FORMAT),
      eqLocation: event.eqLocation ? { lat: event.eqLocation.lat, long: GeometryUtils.normalizeLongitude(event.eqLocation.long) } : null,
      startDate,
      startDateTime,
    });
  }

  private static parseCompleteEvent(event: CompleteHumanizedEvent): CompleteHumanizedEvent {
    return _.extend({}, event, {
      parsedMagnitude: `${roundToDecimal(event.magnitude.min, 3)} - ${roundToDecimal(event.magnitude.max, 3)}`,
      pBaz: numeral(event.pBaz).format('0.0000'),
      teleseismicity: numeral(event.event.teleseismicity).format('0.0000'),
      pSlo: numeral(event.pSlo).format('0.0000'),
      spDuration: event.sPickTime === -1 ? 'NA' : event.sPickTime - event.pPickTime,
    });
  }
}

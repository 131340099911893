















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'ConfirmationDialog',
})
export default class ConfirmationDialog extends Vue {
  @Prop({ default: false }) public open!: boolean;
  @Prop({ default: '' }) public message!: string;
  @Prop({ default: '' }) public header?: string;
  @Prop({ default: '' }) public confirmLabel?: string;
  @Prop({ default: '' }) public dismissLabel?: string;
  @Prop({ default: '' }) public hideConfirmButton?: boolean;
  public isOpen: boolean = false;

  public mounted() {
    this.onModelChange();
  }

  @Watch('open')
  public onModelChange() {
    this.isOpen = this.open;
  }

  public onChange() {
    this.$emit('update:open', this.isOpen);
  }

  public cancel() {
    this.isOpen = false;
    this.$emit('onCancel');
    this.onChange();
  }

  public confirm() {
    this.$emit('onConfirm');
    this.cancel();
  }
}
